<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <h2 class="mb-2">Experiment 3 - Pre-Lab Exercise: Part III</h2>

      <p class="mb-3">
        a) Write the balanced equation for the reaction between hydrochloric acid and aluminum
        metal.
      </p>

      <chemical-notation-input
        v-model="inputs.balancedEquation"
        dense
        :show-note="false"
        class="mb-5"
        :disabled="!allowEditing"
      />

      <p class="mb-0">
        b) What are the safety precautions that go along with using an acid in lab? Select all that
        apply.
      </p>

      <p v-for="option in optionsSafetyPrecautions" :key="'pt-2-' + option.value" class="mb-0 mt-0">
        <v-checkbox
          v-model="inputs.safetyPrecautions"
          :value="option.value"
          class="ml-6 mb-n4 mt-0"
        >
          <template v-slot:label>
            <stemble-latex :content="option.text" />
          </template>
        </v-checkbox>
      </p>

      <p class="mb-n3 mt-5">
        c) When using the formula PV = nRT in this lab, what is the proper value of R?
      </p>

      <v-radio-group v-model="inputs.Rvalue" class="ml-6 mb-n7" :disabled="!allowEditing">
        <v-radio
          v-for="option in optionsR"
          :key="'pt-3-' + option.value"
          class="my-1"
          :value="option.value"
        >
          <template #label>
            <stemble-latex :content="option.text" />
          </template>
        </v-radio>
      </v-radio-group>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex';
import CalculationInput from '@/tasks/components/inputs/CalculationInput.vue';
import ChemicalLatex from '@/tasks/components/displayers/ChemicalLatex.vue';
import ChemicalNotationInput from '@/chemical-equations/components/ChemicalNotationInput.vue';
import NumberValue from '@/tasks/components/NumberValue.vue';
import LatexNumber from '@/tasks/components/displayers/LatexNumber.vue';

export default {
  name: 'OleMiss116Lab3PrelabQ3',
  components: {
    LatexNumber,
    NumberValue,
    ChemicalNotationInput,
    ChemicalLatex,
    CalculationInput,
    StembleLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      // Put a list of inputs or computed properties here to be sent for marking
      inputs: {
        balancedEquation: null,
        safetyPrecautions: [],
        Rvalue: null,
      },
      optionsSafetyPrecautions: [
        {text: 'Wear long pants that cover your entire leg', value: 'longPants'},
        {text: 'Wear splash-proof goggles at all times', value: 'splashProofGoggles'},
        {text: 'Make sure that you are not wearing any dangling jewelry', value: 'danglingJewelry'},
        {text: 'Wear shoes that cover your entire foot', value: 'closedToeShoes'},
      ],
      optionsR: [
        {
          text: '$0.08206\\,\\text{L}\\cdot\\text{atm}/\\text{mol}\\cdot\\text{K}$',
          value: 'LatmPerKmol',
        },
        {
          text: '$0.08206\\,\\text{mol}\\cdot\\text{K}/\\text{L}\\cdot\\text{atm}$',
          value: 'KMolPerLatm',
        },
        {text: '$8.314\\,\\text{J}/\\text{mol}\\cdot\\text{K}$', value: '8314JperMolK'},
        {text: '$4.18\\,\\text{J}/\\text{mol}\\cdot\\text{K}$', value: '418JperMolK'},
      ],
    };
  },
};
</script>
<style scoped></style>
